import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import Home from './Components/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Error from './Components/Error'
import About from './Components/About';
import Contact from './Components/Contact';
import Login from './Components/Login';
import Blogs from './Components/Blogs';
import Private from './Components/Private';
import Admin from './Components/Admin'

export default function Index() {
  return (
    <BrowserRouter>
      <Routes>
<Route path ='/login' element={<Login/>}>

</Route>
        <Route >
          <Route path={'/'} element={<Home />}/>
          <Route path='About' element={<About/>} />
          <Route path='Contact' element={<Contact />} />
          <Route path='Blogs' element={<Blogs />} />
          <Route path='Admin' element={<Private>{<Admin/>}</Private>} />

          
          <Route path='*' element={<Error/>} />

        </Route>



      </Routes>

    </BrowserRouter>


  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Index />)