import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
// import UploadForm from './Upload/UploadForm'
// import History from './Upload/history'
// import Modal from './Upload/modal'
// import { useState } from 'react'
import Header from './Header'
import './About.css'

export default function Blogs() {
  // const [selectedImg, setSelectedImg] = useState(null)
  return (
    <div>
      <Header />
      {/* <Header/> */}
      <Navbar />

      <div className='aboutnam'>

        <div className='aboutdesign1'>
        <div className='about1name'>
          About
        </div>
        </div>
        
      </div>

      <div className='aboutcards'>
        <div className='aboutcard1'>
          1
        </div>
        <div className='aboutcard2'>
          <p><span  >Chhattisgarh is a state presently with 27 states. Bastar
            <br />is a south-part of Chhattisgarh, a state located centrally in India. The area
            <br />of Bastar was 40,836 kilometer square. Later it got divided into 7 districts-
            <br />Bastar, Dantewada, Kanker, Sukma, Narayanpur, Bijapur, Kondagoan.There are many
            <br />local tribes living in this specific part of India, namely- The Gond, The Halba,
            <br />The Mahara, The Thakad, The Dhurvaa, The Abhuj Maria, The Bhatra, The Telunga.
            &nbsp;Population of Chhattisgarh is 25.5 million as
            <br />per 2011 census. The Literacy rate in Chhattisgarh state is 61% according to
            <br />census data 2011.
          </span></p>
        </div>
      </div>


      <div className='aboutcards'>
        <div className='aboutcard2'>
          <p><span  >
            People in Chhattisgarh are mostly affected by the Naxals (Maoist).<br />

            There  were many attack attempted by the<br />
            Maoists group. In 2007, the Maoist killed 55 policemen in Rani Bodli,<br />
            Chhatisgarh. In 2010, 76 of the Central Reserve Police Force were killed in<br />
            Dantewada. In 2013, 32 were killed including Mahendra Karma who was a political<br />
            leader and some congress leader of Chhattisgarh were killed by Maoist in Darbha<br />
            valley, Sukma dist., Chhattisgarh.  In<br />
            2019, a newly elected MLA Bheema Ram Mandavi was killed by a bomb blast in Syamgiri,<br />
            Dantewada Dist., Chhattisgarh. In 30th of August 2019,<br />
            a village head was killed by the Maoist in bade Guddra,<br />
            Dantewada Dist.. And there are many other incidents happen each day.<br />
          </span></p>
        </div>
        <div className='aboutcard3'>


        </div>
      </div>


      <div className='aboutcards'>
        <div className='aboutcard4'>
          1
        </div>
        <div className='aboutcard2'>
          <p><span  >
            The custom of untouchability, caste system, human sacrifice including<br />
            concieved women are there in practice.<br />
            These people believe that their Gods are the nature and Idols,<br />
            they also worship spirits and demons.<br />

            In 2015, more than 50 villages in Bastar region banned Christianity.<br />
            Open air preaching, Evangelism in these villages are difficult and villagers<br />
            who believe in Christ are persecuted.<br />
          </span></p>
        </div>
      </div>


      <div className='aboutcards'>
        <div className='aboutcard2'>
          <p><span  >
            At first in 1987,  Late. Rev. S. Kasirajan from Tamil Nadu State came alone to Bastar region <br />
            along with his wife Mani Megalai and he was the first missionary to start  open<br />
            air preaching in villages. His vision was to turn Bastar to Christ.<br />
            Then on 1993, Rev. J. Ravi Kumar Silva came and joined the ministry.<br />
            Bastar For Christ Missionary Movement was founded by Late. Rev. S. Kasirajan in 1993<br />
            and was reregistered in 2005.The first church was built in Dantewada on 1993 with 30<br />

            believers. The persecution was still going on as the villagers didn’t permitted<br />
            them to get their share of drinking water and renting cycle for ministry. In<br />
            the mist of these troubles they built many churches and appointed many pastors<br />
            under them.<br />

            In 2003,  they built their first hostel in Balood village with 15 children.<br />
            All the children were from single parent and some of them were affected from maoist.<br />



          </span></p>
        </div>
        <div className='aboutcard5'>


        </div>
      </div>




      <div className='aboutcards'>
        <div className='aboutcard6'>

        </div>
        <div className='aboutcard2'>
          <p><span  >

            They struggled a lot for the growth of the minstry and on 5th of November 2011,<br />
            Rev. S. Kasirajan buried his dear wife Mani Megalai and completing 4 years after her death<br />
            he completed his journey and was buried beside his wife.<br />


          </span></p>
        </div>
      </div>


      <div className='aboutcards'>
        <div className='aboutcard2'>
          <p>
            <div>
              <span>

                Present Status
              </span>
            </div>
            <span  >
              After the death of the founder, Presently the organization is directed by
              Rev. J. Ravi Kumar Silva and with his wife R. Staline Milkal Silva.
              Inspite of all challenges, they are continuing the vision of the founder of the ministry.



              The mission was only in Bastar Dist. But now it has been spread
              in whole south of Chhattisgarh state. Currently the organization
              has 60 pastors, 35 churches and 2 hostels with 80 children and 9 staffs.



              Church is being planted on every place where there is no church.


            </span></p>
        </div>
        <div className='aboutcard7'>


        </div>
      </div>





      {/* <UploadForm/> */}
      {/* <History setSelectedImg={setSelectedImg} />
      {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
      <i className="bi bi-1-circle"></i> */}



      <Footer />

    </div>
  )
}
