import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Header from './Header'
import './Contact.css'

export default function Contact() {
  return (
    <div>
      <Header />
      <div className=''>
        {/* <Header/> */}
        <Navbar />
      </div>
      <div className='aboutnam'>

        <div className='aboutdesign1'>
          <div className='about1name'>
            Contact
          </div>
        </div>

      </div>


        <div className='contactcard'>
          <div className='contactcard3'>
            Contact Us

          </div>

          <div className='contactcard2'>
            <a href="mailto:bcmmovement@gmail.com">

              <button className='btn btn-light mailbutton'>MAIL</button>
            </a>
          </div>

        </div>







      <Footer />
    </div>
  )
}
