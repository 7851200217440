import "./gallery.css";
import { useState, useEffect } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,


} from "firebase/storage";
import { storage } from "../Firebase/firebase";
import { v4 } from 'uuid';
import { motion} from "framer-motion";

import Cookies from 'js-cookie'


const Gallery = ({ setSelectedImg }) => {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "images/");
  const uploadFile = () => {
    if (imageUpload == null || cookie!=='gotham') return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };
 
const cookie = Cookies.get('user');

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);

        });
      });
    });
  }, []);

  return (
    <center>
      <div className="gallery">
        <div className={cookie === 'gotham' ? "viewer" : "hidemenu"}>

          <input
            type="file"
            onChange={(event) => {setImageUpload(event.target.files[0]);
            }}
          />
          <button onClick={uploadFile}> Upload Image</button>
        </div>

        <motion.div className="img-grid">
       
          {imageUrls.map((url) => {
            return (
              <motion.div className="img-wrap"
        
                layout
                whileHover={{ opacity: 1 }}
                onClick={() => setSelectedImg(url)}>
                <img className="" src={url} alt='error' />;
              </motion.div>
            )
          })}
        </motion.div>

      </div>
    </center>
  );
}

export default Gallery;