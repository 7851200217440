import React from 'react'
import './modal.css'
import { motion } from 'framer-motion'

const Modal = ({ selectedImg, setSelectedImg }) => {

    const handleClick = (e) => {

        if (e.target.classList.contains('backdrop')) {
            setSelectedImg(null)
        }
    }

    const handleClicks = (e) => {


        setSelectedImg(null)

    }
    return (

        <motion.div className='backdrop' onClick={handleClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >


            <motion.img src={selectedImg} alt="enlarged pic"
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
            />
            <button className='spanclose' onClick={handleClicks}>x</button>
        </motion.div>

    )
}
export default Modal;
