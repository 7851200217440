import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Navbar.css'
import { BiLogIn, BiMenu } from "react-icons/bi";
import {motion}from 'framer-motion'

export default function Navbar() {
  const [menu, usemenu] = useState(true);
  const [click, setclick] = useState(false);

  const handleClick = () => {
    setclick(!click);

  }

  // const clicks = () => {
  //   setclick(!click);
  // }

  const showbuton = () => {
    if (window.innerWidth <= 720) {
      usemenu(false);
    }
    else {
      usemenu(true);
      setclick(false)
    }
  }


  useEffect(() => {
    showbuton();
  }, []);

  
  window.addEventListener('resize', showbuton)
  // window.addEventListener('click', clicks)

  return (

    <div className="nav"  >
      <div className="welcome">
    
        <nav>


          <motion.ul className={menu ? "alllinks" : "hidemenu"}
           initial={{ x: 400, opacity:0 }}
           animate={{ x: 0,opacity:1 }}
           transition={{ delay: 0.2 }}
          >

            <NavLink className="links" to="/">Home</NavLink>


            <NavLink className="links" id="" to="/blogs">Blogs</NavLink>


            <NavLink className="links" id="" to="/contact">Contact</NavLink>
            <NavLink className="links" id="" to="/about">About</NavLink>
            <NavLink className="links" id="" to="/login"><BiLogIn /></NavLink>




          </motion.ul>


        </nav>

        <div className='slidemenu1'>
          <div className={menu?'hidemenu':'small'}>
            <BiMenu className={menu ? 'show' : 'nomenu' && click ? 'black' : 'nomenu'} onClick={handleClick} ></BiMenu>
          </div>
          <motion.ul
          initial={{ x: 400, opacity:0 }}
          animate={{ x: 0,opacity:1 }}
          transition={{ delay: 0.2 }}
          className={click ? "slidemenu" : 'hidemenu'}>
            <NavLink className="link" to="/">Home</NavLink>


            <NavLink className="link" id="" to="/blogs">Blogs</NavLink>


            <NavLink className="link" id="" to="/contact">Contact</NavLink>
            <NavLink className="link" id="" to="/about">About</NavLink>
            <NavLink className="link" id="" to="/login">Login</NavLink>

          </motion.ul>
        </div>


      </div>
    </div>
  )
}
