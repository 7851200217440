import React from 'react'
import './footer.css'
// import {motion}from 'framer-motion'
import { BsInstagram, BsFacebook, BsYoutube} from 'react-icons/bs';



export default function Footer() {

  function toggle(elementId) {
    const element = document.getElementById(elementId);

    if (element.style.display === "block") {
      element.style.display = "none"
    }
    else {
      element.style.display = "block";
    }
  }


  return (
    <div className="top-content px-4 text-center footmain">
      <div className="row gx-5 ">
        <div className="col">
          <div className="p-3 ">
            <div className='footleft'>
              <div>
                <button className='footerbtn' onClick={() => { toggle('target1') }} id="toggler">About</button>
              <div className='footline'></div>
                
                <div

                  id="target1">BCM Movement extends its motive in fulfilling the will of our Lord Jesus Christ.</div>

              </div>
              <div>
              <button className='footerbtn' onClick={() => { toggle('sociallinks2') }} id="toggler">Social</button>

              <div className='footline'></div>

                <div id='sociallinks2'>
                        <a className='sociallinks3'  href='https://www.instagram.com/bcm.movement/' target="_blank" rel="noreferrer"><BsInstagram /></a>
                        <a className='sociallinks3' href='/'><BsFacebook /></a>
                        <a className='sociallinks3' href='https://www.youtube.com/@bcmmovement3248' target="_blank" rel="noreferrer"><BsYoutube /></a>
                        {/* <a className='sociallinks3' href='/'><BsWhatsapp /></a> */}
                        

                    </div>


              </div>
              <div>
                <button className='footerbtn' onClick={() => { toggle('target3') }} id="toggler">Sponsor Us</button>
              <div className='footline'></div>

                <div id="target3">Contact</div>

              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="p-3 ">

            <div>
              St. Peters Church,
              <br />
              Dantewada,
              <br />
              Chhattisgarh,
              <br />
              India.
            </div>
            <div>
              ph.no- +91 94252 62446
            </div>
            <div>
              bastarforchristmovement@gmail.com

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
