import React, { useState } from 'react'
import './login.css'
import axios from 'axios'
import Cookies from 'js-cookie'


export default function Login() {

  // const axios = require('axios');

  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
 

  

  const login = () => {



    axios.post('https://api.bcmmovement.in/auth', { username: username, password: password })
      .then((res, err) => {
        if (err) {
          return err;
        }
        // if (res.data === 'ok') {
        //   window.location.replace('http://localhost:3000/admin')
        // }
        if (res.data === 'lkjhgfdsa') {
        Cookies.set("user","gotham",{path:"/"})
          window.location.replace('/')
        }
        else if(res.data==='error') {
        Cookies.remove("user","",{path:"/"})
        alert('invalid')
        }
        else{
        Cookies.remove("user","",{path:"/"})

        }
      }
      )
  }

  // window.addEventListener('')

  return (
    <div className='flex-fill sm'>
      <center>

        <div className='box'>
          <div className='logininfo'>
            <div className='txt'>
              Username
            </div>

            <div className='username'>
              <input type='text' className='inputtxt' placeholder='Enter Username' name='username' id='username' onChange={(e) => { setusername(e.target.value) }}></input>
            </div>
            <div className='txt'>
              Password
            </div>
            <div className='password'>
              <input type='password' className='inputtxt' placeholder='Enter Password' name='password' id='password' onChange={(e) => { setpassword(e.target.value) }}></input>
            </div>
            <div className='button'>
              <button className='loginb' onClick={login} >Login</button>

            </div>
          </div>
        </div>

      </center>


    </div>
  )
}
