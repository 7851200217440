import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

var firebaseConfig = {
    apiKey: "AIzaSyCSUvFwvKT271hiro4XS73m3_P6tjyF5u8",
    authDomain: "bcm-firebase.firebaseapp.com",
    projectId: "bcm-firebase",
    storageBucket: "bcm-firebase.appspot.com",
    messagingSenderId: "266377146969",
    appId: "1:266377146969:web:43f0349f1326f6117a3748"
  };

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);