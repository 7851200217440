import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import './home.css'
import Header from './Header'
import { motion } from 'framer-motion'


export default function Home() {

  const [screensize, setscreensize] = useState(true)

  function reveal() {
    var reveals = document.querySelectorAll(".revealed");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }



  const showbuton = () => {
    if (window.innerWidth <= 720) {
      setscreensize(false);
    }
    else {
      setscreensize(true);
    }
  }

  useEffect(() => {

    showbuton()
  }, [])

  if (screensize === true) {


    window.addEventListener("scroll", reveal);
    reveal();

  }
  window.addEventListener('resize', showbuton)


  // To check the scroll position on page load

  return (
    <div className='top-content-sm md'>
      <Header />

      {/* <div>navbar</div> */}

      <div className='wallpaper'>
        <Navbar />

      </div>
      <div className='hover'>
        If God’s love is for anybody anywhere,<br /> it’s for everybody everywhere.
        – Edward Lawlor
      </div>
      <div className='line'></div>

      <div className='homes'
        // layout
        // onscroll={{opacity:0}}
        initial={{ opacity: 0 }}
        transition={{ delay: 0 }}
        animate={{ opacity: 1 }}>
        <div className='homed111'>

          <div className='homed1 revealed'>
            <div id='homed21'>

              January
            </div>
            <br />
            <div id='homed22'>

              BIBLE TRAINING PROGRAM
            </div>

          </div>
          <div className='homed2 revealed'>
            <div id='homed21'>

              April
            </div>


            <br />
            <div id='homed22'>
              PASTOR'S FAMILY CAMP
            </div>

          </div>
        </div>
        <div className='homed222'>

          <div className='homed1 revealed'>
            <div id='homed21'>

              May
            </div>


            <br />
            <div id='homed22'>

              CHILDREN'S MEETING
            </div>


          </div>


          <div className='homed2 revealed'>

            <div id='homed21'>
              August
            </div>

            <br />
            <div id='homed22'>

              COUPLE'S MEETING
            </div>

          </div>
        </div>
        <div className='homed333'>
          <div className='homed1 revealed'>
            <div id='homed21'>

              October
            </div>

            <br />
            <div id='homed22'>

              PRAYER CONFERENCE
            </div>

          </div>
          <div className='homed2 revealed'>
            <div id='homed21'>

              November
            </div>

            <br />
            <div id='homed22'>

              YOUTH MEETING
            </div>

          </div>
        </div>
      </div>
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}

        className='homeabout'>


        <div className='aboutname'>
          <div id='aboutbcm'>
            BCM MOVEMENT
          </div>
          <div id='aboutabout'>
            ABOUT US
          </div>
          <div id='aboutpreach'>
            We Preach the Gospel of our Lord Jesus Christ and Share the Love, Peace and Joy he has given us.

          </div>
          <button className='readmore' onClick={() => { window.location.replace('/about') }}>
            Read More
          </button>
        </div>
      </motion.div>

      <div className='totalcards'>
        <div className='card1'>
          Founder
          <div className='card12'>
            S Kasirajan
          </div>
        </div>
        <div className='card2'>

        </div>
      </div>

      <div className='totalcards'>
        <div className='card3'>

        </div>
        <div className='card1'>
          Director
          <div className='card12'>
            Rev J Ravi Silva
          </div>
        </div>

      </div>
      <Footer />

    </div >

  )
}
