import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
// import UploadForm from './Upload/UploadForm'
import Gallery from './Upload/gallery'
import Modal from './Upload/modal'
import { useState } from 'react'
import Header from './Header'

export default function Blogs() {
  const [selectedImg, setSelectedImg] = useState(null)
  return (
    <div>
      <Header />
      <div className=''>
        {/* <Header/> */}
        <Navbar />
      </div>
      <div className='aboutnam'>

        <div className='aboutdesign1'>
          <div className='about1name'>
            Photos
          </div>
        </div>

      </div>
      {/* <UploadForm/> */}
      <Gallery setSelectedImg={setSelectedImg} />
      {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />}
      <i className="bi bi-1-circle"></i>
      <Footer />

    </div>
  )
}
