// import React from "react";

import Cookies from 'js-cookie'
import { Navigate } from "react-router-dom";




const Private = ({ children }) => {
    const cookie = Cookies.get('user');

return cookie ==='gotham'? children:<Navigate to='/home'/>
}


export default Private
