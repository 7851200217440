import React from 'react'

import './error.css'

export default function Error() {
  return (
    <div className='top-content-sm md'>
      <div className='wallpaper'>
      {/* <Header/> */}
      </div>
      <div className='line'></div>
      Error 404 Not Found
     

      </div>
      
  )
}
