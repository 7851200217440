import React from 'react'
import './header.css'
import { BsInstagram, BsFacebook, BsYoutube } from 'react-icons/bs';
import { motion } from 'framer-motion'


export default function Header() {


    return (
        <div className='header'>
            <div className='topcontent'>

                <motion.a
                    layout
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ rotate: 360, scale: 1, opacity: 1 }}
                    transition={{
                        type: "tween",
                        stiffness: 360,
                        damping: 20
                    }}
                    href='/'><img className='monoimg' src='./mono.ico' id='mono' alt='error'></img></motion.a>
                <motion.div className='bcmall'
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.1 }}
                >
                    <div className='bcmname'>BASTAR FOR CHRIST MISSIONARY MOVEMENT</div>
                    <div className='bcmmail'>Bcmmovement@gmail.com</div>

                </motion.div>

                <motion.div className='righthead'
                    layout
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "tween",
                        stiffness: 360,
                        damping: 20
                    }}
                >
                    <div className='headbcm'>
                        Bcm
                    </div>
                    <div className='headdiv'>
                        |
                    </div>
                    <div className='sociallinks'>
                        <a className='sociallinks1' href='https://www.instagram.com/bcm.movement/' target="_blank" rel="noreferrer"><BsInstagram /></a>
                        <a className='sociallinks1' href='/' target="_blank"><BsFacebook /></a>
                        <a className='sociallinks1' href='https://www.youtube.com/@bcmmovement3248' target="_blank" rel="noreferrer"><BsYoutube /></a>
                        {/* <a className='sociallinks1' href='/'><BsWhatsapp /></a> */}

                    </div>

                </motion.div>
            </div>

        </div>
    )
}
